import React from "react";
/* 
 * @About page
 */

/* material-ui elements */
import { Avatar, Typography, Container, Grid } from '@mui/material';

/* components */
import NavigationBar from '../NavigationBar';
import SocialButtons from '../SocialButtons';
import ContactForm from '../ContactForm';

/* styles */
import { AboutMeContent, CenterDivContent } from '../../styles/styles';

const About = () => {

    return (
        <>
            <Container 
				maxWidth='md'
			>
                <Grid 
					container 
					maxWidth='md' 
					spacing={0} 
					alignItems="center"
				>
                    <Grid 
						item 
						xs={12} 
						style={{textAlign: "center"}} 
						justify="center"
					>
                        <Typography 
							sx={{ marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 35 }}
						>
							ABOUT ME
						</Typography>
                        <div 
							style={CenterDivContent}>
                            	<Avatar 
									src='../../assets/icons/favicon.png' 
									sx={{ width: 200, height: 200 }} 
								/>
                        </div>
                    </Grid>
                    <Grid 
						item 
						xs={12}
					>
                        <Typography 
							sx={{ position: 'relative', marginTop: 10, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_GREETING}
						</Typography>
                        <Typography 
							sx={{ position: 'relative', marginTop: 2, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_ABOUT_ME}
						</Typography>
                        <Typography 
							sx={{ position: 'relative', marginTop: 2, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_SIGNATURE}
						</Typography>
                    </Grid>
                    <Grid 
						item 
						xs={12} 
						style={{textAlign: "center"}}
					>
                        <Typography 
							sx={{ marginTop: 5, color: 'gray', fontWeight: 'bold', fontSize: 20 }}
						>
							CONTACT ME
						</Typography>
                        <Container 
							maxWidth='lg' 
							sx={AboutMeContent}
						>
							<SocialButtons />
						</Container>
                        <div 
							sx={CenterDivContent}
						>
                            <ContactForm />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Typography 
				variant='h6' 
				color='primary' 
				align='center' 
				style={{padding: 30}}
			>
				&copy; 2016 - {new Date().getFullYear()} Alejandro Hernandez
			</Typography>
        </>
    );
}

export default About;
