
import { useState } from 'react';

import { slideshowTransition, slideshowScaleTransition } from '../styles/styles';

import { MobileStepper, Button, Container, Typography, Toolbar } from '@mui/material';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

export function Slideshow ({imagesArray, scale, titleBar}) {
    
    // constants
    // alias for => auto-play
    const Carousel = autoPlay(SwipeableViews);
    
    // hooks
    const [activeStep, setActiveStep] = useState(0);
    
    // hook methods
    const handleStepChange = (index) => {
        setActiveStep(index);
    };
    
    const handleNext = () => {
        setActiveStep((previousActiveStep) => previousActiveStep + 1);
    };
    
    const handlePrevious = () => {
        setActiveStep((previousActiveStep) => previousActiveStep - 1);
    };

	const getTitleFromPath = (path) => {
        if (path === undefined) {
            return '';
        }
		return path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.')).toUpperCase();
	} 

    let imagesArraySize = imagesArray.length;
    
    return (
        <>
			{
				titleBar && 
				<Toolbar 
					sx={{justifyContent: 'center'}}
				>
					<Typography 
						sx={{color: 'white', alignContent: 'center'}}
					>
						{getTitleFromPath(imagesArray[activeStep])}
					</Typography>
				</Toolbar>
			}
            <Carousel 
                axis={"x"} 
                index={activeStep} 
                onChangeIndex={handleStepChange}
            >
                {
					imagesArray.map((step, index) => ( 
						<Container 
							component='img' 
							src={imagesArray[activeStep]} 
							sx={(scale) ? slideshowScaleTransition : slideshowTransition} 
							onContextMenu={(e) => {e.preventDefault()}}
						/>
					))
				}
            </Carousel>
            <MobileStepper 
                variant="dots" 
                steps={imagesArraySize} 
                position="static" 
                activeStep={activeStep}
                nextButton={
                    <Button 
                        size="small" 
                        onClick={handleNext} 
                        disabled={activeStep === imagesArraySize - 1} 
                        disableFocusRipple 
                        disableRipple
                    >
                        Next
                        <KeyboardArrowRight 
                            style={(activeStep === imagesArraySize - 1) ? {fill: '#5A595A'} : {fill: 'white'}}
                        />
                    </Button>
                }
                backButton={
                    <Button 
                        size="small" 
                        onClick={handlePrevious} 
                        disabled={activeStep === 0} 
                        disableFocusRipple 
                        disableRipple
                    >
                        <KeyboardArrowLeft 
                            style={(activeStep === 0) ? {fill: '#5A595A'} : {fill: 'white'}}
                        />
                        Previous
                    </Button>
                }
            />
        </>
    );
    
}