import { useState } from "react";

import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu, Tabs, Tab, Button } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';

import { companyLogoWhiteAnimation, companyLogoBlueAnimation } from '../styles/styles';

const Logo = ({link}) => {
	let animate = link === 0;
	return (
		<>
			<Typography
				color='secondary'
				sx={(animate) ? companyLogoBlueAnimation : {}}
			>
				A
			</Typography>
			<Typography
				variant='p'
				color='secondary'
				component='p'
				sx={(animate) ? companyLogoBlueAnimation : {}}
			>
				+
			</Typography>
			<Typography
				color='primary'
				sx={(animate) ? companyLogoWhiteAnimation : {marginLeft: 1, marginRight: 2}}
			>
				Media Solutions, LLC
			</Typography>
		</>
	);
}

const NavigationBar = ({link}) => {

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);

	const isMobileMenuOpen = mobileMoreAnchorEl;

	const pages = [
		{
			name: "Home",
			address: "/",
			id: 0
		},
		{
			name: "Cinematography",
			address: "/cinematography",
			id: 1
		},
		{
			name: "Photography",
			address: "/photography",
			id: 2
		}
	];

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id="mobile-menu"
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{
				pages.map((page) => (
					<MenuItem key={page.name} onClick={handleMenuClose}>
						<Button 
							variant="text"
							href={page.address}
							fullWidth
							sx={{justifyContent: 'left'}}
							disabled={page.id === link}
						>
							{page.name}
						</Button>
					</MenuItem>
					)
				)
			}
		</Menu>
  );

	return (
		<Box 
			sx={{ flexGrow: 1 }}
		>
			<AppBar 
				position="fixed"
			>
			<Toolbar>
				<Logo link={link}/>
				<Box 
					sx={{ flexGrow: 1 }} 
				/>
				<Box 
					sx={{ display: { xs: 'none', md: 'flex' } }}
				>
					<Tabs
						textColor='secondary'
						indicatorColor='secondary'
						value={link}
					>
						{
							pages.map((page) => (
								<Tab
									label={page.name}
									value={page.id}
									href={page.address}
								/>
								)
							)
						}
					</Tabs>	
				</Box>
				<Box 
					sx={{ display: { xs: 'flex', md: 'none' } }}
				>
					<IconButton
						size="large"
						aria-label="show more"
						aria-controls="mobile-menu"
						aria-haspopup="true"
						onClick={handleMobileMenuOpen}
						color="inherit"
					>
						<MoreIcon />
					</IconButton>
				</Box>
			</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</Box>
	);
}

export default NavigationBar;
