
import { ThemeProvider } from '@mui/material/styles';

import AppRoutes from './AppRoutes.js';

// theme
import darkTheme from './styles/theme.js';


const App = () => {
	return (
		<ThemeProvider
			theme={darkTheme}
		>
			<AppRoutes/>
		</ThemeProvider>
	);
};

export default App;
