import React from "react";

// @material-ui/core components
import { Button } from '@mui/material'


/* social media icons */
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocalMovies from '@mui/icons-material/LocalMovies';


const SocialButtons = () => {

  return (
	<>
		<Button 
			size='large' 
			href={process.env.REACT_APP_FBREF} 
			target='_blank' 
			rel='noreferrer'
		>
			<FacebookIcon 
				style={{fill: '#4267B2', width: 50, height: 50}}
			/>
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_INSTAREF} 
			target='_blank' 
			rel='noreferrer'
		>
			<InstagramIcon 
				style={{fill: '#833AB4', width: 50, height: 50}}
			/>
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_YTREF} 
			target='_blank' 
			rel='noreferrer'
		>
			<YouTubeIcon 
				style={{fill: '#FF0000', width: 50, height: 50}}
			/>
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_LIREF} 
			target='_blank' 
			rel='noreferrer'
		>
		<LinkedInIcon 
			style={{fill: '#0072b1', width: 50, height: 50}}
		/>
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_IMDBREF} 
			target='_blank' 
			rel='noreferrer'
		>
		<LocalMovies 
			style={{fill: '#0072b1', width: 50, height: 50}}
		/>
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_EMAILREF} 
			target='_blank' 
			rel='noreferrer'
		>
			<EmailIcon 
				style={{fill: '#0072b1', width: 50, height: 50}}
			/>
				&nbsp;&nbsp;{process.env.REACT_APP_EMAIL}
		</Button>
		<Button 
			size='large' 
			href={process.env.REACT_APP_PHONEREF} 
			target='_blank' 
			rel='noreferrer'
		>
			<PhoneIphoneIcon 
				style={{fill: '#0072b1', width: 50, height: 50}}
			/>
			{process.env.REACT_APP_PHONE}
		</Button>
	</>
  );
};

export default SocialButtons;