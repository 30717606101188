
/* 
 * @Home page
 */

/* components */
import NavigationBar from '../NavigationBar';

/* utils */
import { MultiMedia } from '../../utils/MultiMedia';

// material ui
import { Container, Typography, Grid, Avatar } from '@mui/material';

// custom components
import SocialButtons from '../SocialButtons';
import ContactForm from '../ContactForm';

// custom styles
import { slideshowFadeInto, AboutMeContent, CenterDivContent } from '../../styles/styles';
import { VideoPlayer} from '../../styles/styles';


import { Slideshow } from '../Slideshow';

/* body */
const Home = () => {

	const imagesArray = Object.values(MultiMedia('slideshow')[0]);

	// local variables
	// styling
	let videoPlayerDivStyling = {
		position: 'relative', 
		width: '100%', 
		height: 0, 
		paddingBottom: '56.25%'
	};

	let videoPlayeriFrameStyling = {
		position: 'absolute', 
		top: 0, 
		left: 0, 
		width: '100%', 
		height: '100%'
	};

	let youtubeAllowedPlugins = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;'

	/* load image buffer before slideshow presentation -- performance is not important */
	for (let i = 0; i < imagesArray.length; i++) {
		let image = new Image();
		image.src = imagesArray[i];
	}

	return (
		<>
			<NavigationBar 
				link={0}
			/>
				<Container 
					maxWidth='lg' 
					sx={slideshowFadeInto}
				>
					<Container
					maxWidth='lg'
					sx={VideoPlayer}
				>
					<div 
						style={videoPlayerDivStyling}
					>
						<iframe 
							width='100%' 
							style={videoPlayeriFrameStyling}
							src='https://www.youtube.com/embed/GT2ioVrZ7Bc?playlist=GT2ioVrZ7Bc&rel=0&autoplay=1&mute=1&loop=1'
							frameBorder='0' 
							allow={youtubeAllowedPlugins}
							title='iframe'
						/>
					</div>
				</Container>
				<Grid 
					container 
					maxWidth='lg' 
					spacing={0} 
					alignItems="center"
				>
					<Grid 
						item 
						xs={12} 
						style={{textAlign: "center"}} 
						justify="center"
					>
						<Typography 
							sx={{ marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 35 }}
						>
							ABOUT ME
						</Typography>
						<div 
							style={CenterDivContent}>
								<Avatar 
									src='../../assets/icons/Alex.jpg' 
									sx={{ width: 200, height: 200 }} 
								/>
						</div>
					</Grid>
					<Grid 
						item 
						xs={12}
					>
						<Typography 
							sx={{ position: 'relative', marginTop: 10, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_GREETING}
						</Typography>
						<Typography 
							sx={{ position: 'relative', marginTop: 2, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_ABOUT_ME}
						</Typography>
						<Typography 
							sx={{ position: 'relative', marginTop: 2, color: 'white', fontSize: 20 }}
						>
							{process.env.REACT_APP_SIGNATURE}
						</Typography>
					</Grid>
					<Grid 
						item 
						xs={12} 
						style={{textAlign: "center"}}
					>
						<Typography 
							sx={{ marginTop: 5, color: 'gray', fontWeight: 'bold', fontSize: 20 }}
						>
							CONTACT ME
						</Typography>
						<Container 
							maxWidth='lg' 
							sx={AboutMeContent}
						>
							<SocialButtons />
						</Container>
						<div 
							sx={CenterDivContent}
						>
							<ContactForm />
						</div>
					</Grid>
				</Grid>
				<Typography 
					variant='h5' 
					color='primary' 
					align='center' 
					style={{padding: 30}}
				>
					Companies I have worked with
				</Typography>
				<Typography 
					variant='h6' 
					color='primary' 
					align='center'
					style={{padding: 30}}
				>
					HEB &diams; Facebook Gaming &diams; JCPenney &diams; Indian Motorcycle &diams; FoxIt &diams; BodyArmor &diams; BitDefender &diams; San Antonio Zoo &diams; Orange Theory &diams; Soma Vida Wellness Center
				</Typography>
				<Typography 
					variant='h6' 
					color='primary' 
					align='center' 
					style={{padding: 30}}
				>
					&copy; 2016 - {new Date().getFullYear()} Alejandro Hernandez
				</Typography>
			</Container>
		</>
	);
}

export default Home;
