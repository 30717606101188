
/* @material-ui components */
import {Typography, ImageListItem} from '@mui/material';
import { useParams } from 'react-router-dom';

/* utils */
import { MultiMedia } from '../../utils/MultiMedia';
import styles from '../../styles/styles.module.css'
/* body */
const AlbumImages = () => {

	const params = useParams();
    const images = Object.values(MultiMedia('photos_'+params.name, params.name))[0];

    return (
        <>
			<div 
				style={{marginTop:15}}
			>
				<Typography 
					variant='h4' 
					color='primary' 
					align='center'
				>
					{params.name}
				</Typography>
			</div>
			<div 
				className={styles.albumListDiv+" "+styles.centerDiv}
			>
				{
					images.map((step, index) => (
						<ImageListItem className={styles.imgCard} key={images[index]}>
							<img
								src={images[index]}
								className={styles.coverImg}
								loading='lazy'
								style={{width: 350, height: 420}}
							/>
						</ImageListItem>
					))
				}
			</div>
			<Typography 
				variant='h6' 
				color='primary' 
				align='center' 
				style={{padding: 30}}
			>
				&copy; 2016 - {new Date().getFullYear()} Alejandro Hernandez
			</Typography>
		</>
    );
}

export default AlbumImages;
