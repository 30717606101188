
/* 
 * @Photography page
 */

/* components */
import NavigationBar from '../NavigationBar';
import { AlbumList } from '../AlbumList';
import InstaFeed from '../InstaFeed';

/* material-ui elements */
import { Typography, Container } from '@mui/material';

/* styles */
import { AlbumContent } from '../../styles/styles';
import { InstaFeedContent } from '../../styles/styles';

const Photography = () => {

    return (
        <>
            <NavigationBar 
				link={2}
			/>
            <Typography 
				variant='h4' 
				color='gray' 
				align='center' 
				fontWeight='bold'
				sx={{
					marginTop: 12
				}}
			>
				Instagram
			</Typography>
            <Container 
				maxWidth='lg' 
				sx={InstaFeedContent}
			>
				<InstaFeed 
					token={process.env.REACT_APP_INS_TOKEN} 
					counter="12"
				/>
			</Container>
			<Container 
				maxWidth='lg' 
				sx={AlbumContent}
			>
				<AlbumList/>
			</Container>
            <Typography 
				variant='h6' 
				color='primary' 
				align='center' 
				style={{padding: 30}}
			>
				&copy; 2016 - {new Date().getFullYear()} Alejandro Hernandez
			</Typography>
        </>
    )
}

export default Photography;
