
/* material-ui elements */
import { Typography, Button, Card, CardHeader, Avatar, CardActions, CardMedia } from '@mui/material';

/* material-ui icons */
import YouTubeIcon from '@mui/icons-material/YouTube';
import {VideoCardsStyle} from '../styles/styles.js'

export function VideoCard({title, subtitle, videoUrl, callback, videoId, videoOnPlayer}) {
	
	const maxTitleLength = 29;
	const videoUrlSettingsLength = 21; 
	
	let conformTitleLength = (videoTitle) => {
		return (videoTitle.length > maxTitleLength) ? videoTitle.substring(0, maxTitleLength) + '...' : videoTitle;
	}
	
	let getImageUrl = (url) => {
		return 'https://img.youtube.com/vi/' + url.substring(url.lastIndexOf('/') + 1, url.length - videoUrlSettingsLength) + '/hqdefault.jpg';
	}
	
	return (
		<Card sx={VideoCardsStyle}>
			<CardHeader 
				avatar={
					<Avatar 
						sx={{bgcolor: 'inherit'}}
					>
						<YouTubeIcon 
							style={{fill: 'red'}}
						/>
					</Avatar>
				}
				title={conformTitleLength(title)}
				subheader={subtitle}
			/>
			<CardMedia 
				component='img' 
				height='150' 
				image={getImageUrl(videoUrl)} 
				alt={title} 
			/>
			<CardActions disableSpacing>
				<Button 
					variant='text' 
					onClick={() => callback(videoId)} 
					fullWidth
					disabled={videoId === videoOnPlayer}
					disableElevation
					startIcon={
						<YouTubeIcon 
							style={(videoId === videoOnPlayer) ? {fill: '#5A595A'} : {fill: 'red'}}
						/>
					}
				>
					<Typography variant='body2'>
						{
							(videoId === videoOnPlayer) ? 'Playing' : 'Play'
						}
					</Typography>
				</Button>
			</CardActions>
		</Card>
	);
}