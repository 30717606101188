
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// pages
import Home from './components/pages/Home';
import Cinematography from './components/pages/Cinematography';
import Photography from './components/pages/Photography';
import About from './components/pages/About';
import AlbumImages from "./components/pages/AlbumImages";

const AppRoutes = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route 
						exact 
						path='/' 
						element={<Home/>} 
					/>
					<Route 
						exact 
						path='/cinematography' 
						element={<Cinematography/>} 
					/>
					<Route 
						exact 
						path='/photography' 
						element={<Photography/>} 
					/>
					<Route 
						exact 
						path='/about-me' 
						element={<About/>} 
					/>
					<Route
						path='/album-photos/:name'
						element={<AlbumImages />}
					/>

				</Routes>
			</BrowserRouter>
		</>
	);
}

export default AppRoutes;
