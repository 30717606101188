
/* @material-ui components */
import { Typography, ImageList, ImageListItem, ImageListItemBar, Box } from '@mui/material';

/* utils */
import { MultiMedia } from '../utils/MultiMedia';

// needs to be made mobile friendly here

export function PosterImages({year}) {

	const posters = Object.values(MultiMedia('posters-' + year))[0];

	const getTitleFromPath = (path) => {
		return path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
	}

	return (
		<>
			<Typography 
				variant='h4' 
				color='primary' 
				align='center'
			>
				<br/>
				{year}
			</Typography>
			<ImageList>
				<ImageListItem key="Subheader" cols={3} />
				{
					posters.map((item) => (
						<ImageListItem key={item}>
							<img 
								src={item}
								loading='lazy'
								style={{width: 350, height: 525}}
							/>
							<ImageListItemBar 
								title={
									<Typography 
										variant='h6'
									>
										{
											getTitleFromPath(item)
										}
									</Typography>
								}
								position='below'
								sx={{color: 'white'}}
							/>
						</ImageListItem>
					))
				}
			</ImageList>
		</>
	);
}