
/*
 * define @aplusmediasite animations
 */

import { keyframes } from '@mui/system';

export const fadeInto = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;