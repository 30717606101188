
/*
 * enable dark theme regardless of user preferences
 */

/* @material-ui */
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',  // white color
      },
      secondary: {
        main: '#3D6B9F',  // custom color
      }
    },
  });

export default darkTheme;