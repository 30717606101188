import React from "react";
import styles from '../styles/styles.module.css'
/* @material-ui components */
import {Typography, ImageListItem, ImageListItemBar} from '@mui/material';

/* utils */
import { MultiMedia } from '../utils/MultiMedia';

/* styles */

export function AlbumList() {

	const data = Object.values(MultiMedia('albums'));

	const albumList = Array.from(data[1]);


	const handleOnClick = (e,albumName) => {
		window.open(`album-photos/${albumName}`,'_blank')
    };

	return (
		<>
			<Typography 
				variant='h4' 
				color='gray' 
				align='center' 
				fontWeight='bold'
			>
				Albums
			</Typography>
			<div 
				className={styles.centerDiv}
			>
				{
					albumList.map((step, index) => (
						<ImageListItem key={index} className={styles.imgCard}>
							<img
								src={albumList[index][1]}
								className={styles.coverImg}
								loading='lazy'
								style={{width: 350, height: 400, objectFit:'cover',  cursor: 'pointer'}}
								onClick={(e) => handleOnClick(e,albumList[index][0])}
							/>
							<ImageListItemBar
								className={styles.coverImgText}
								title={<span><Typography variant='h6' align='center'>{albumList[index][0]}</Typography></span>}
								subtitle={
									<span>
												<Typography align='center'>Alejandro Hernandez</Typography>
											</span>
								}
								sx={{color: 'white', width:'100%',align:'center'}}
							/>
						</ImageListItem>
					))
				}
			</div>
		</>
	);
}