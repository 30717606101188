import React, { useState } from 'react'

/* material-ui elements */
import { Button, TextField } from '@mui/material';

/* components */
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';

/* icons */
import SendIcon from '@mui/icons-material/Send';

function ContactForm() {

    /* EMAILJS Values */
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

    /* TextField Values */
    const [phoneValue, setPhoneValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [subjectValue, setSubjectValue] = useState("");
    const [messageValue, setMessageValue] = useState("");
    const [buttonValue, setButtonValue] = useState("Send Message");
    const [errorValue, setErrorValue] = useState(Boolean);

    var errors = {
        email: "Please enter valid email address.",
        text: "Please refresh and try again."
    };

    var template = {
        from_name: nameValue,
        message: messageValue,
        reply_to: emailValue,
        subject: subjectValue,
        phone_number: phoneValue,
    };

    const onSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Sending message...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => { Swal.showLoading() },
            background: '#212121',
            color: '#3D6B9F'
        });
        emailjs.send(SERVICE_ID, TEMPLATE_ID, template, PUBLIC_KEY)
        .then((result) => {
            Swal.close();
            setButtonValue("Message sent");
            Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully',
                background: '#212121',
                color: '#3D6B9F',
                confirmButtonColor: '#3D6B9F'
            })
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                icon: 'error',
                title: 'Ooops, something went wrong.',
                text: errors.text,
                background: '#303030',
                color: '#3D6B9F',
                confirmButtonColor: '#3D6B9F'
            })
        });
        e.target.reset()
    }

    const handleOnChange = (e) => {

        if (e.target.id === 'name') {
            setNameValue(e.target.value);
        }
        if (e.target.id === 'phone-number') {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneValue(formattedPhoneNumber);
        }
        if (e.target.id === 'email') {
            setEmailValue(e.target.value);
            const validEmail = formatEmail(e.target.value);
            if (validEmail) { 
                setErrorValue(false);
            }
            else {
                setErrorValue(true);
            }
        }
        if (e.target.id === 'subject') {
            setSubjectValue(e.target.value);
        }
        if (e.target.id === 'message') {
            setMessageValue(e.target.value);
        }

      };

    function formatPhoneNumber(v) {
        if (!v) return v;

        //Only digits as input
        const phoneNumber = v.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
      
        //Start formatting
        if (phoneNumberLength < 4) return phoneNumber;
      
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
    }

    function formatEmail(v) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(v)) {
          return false;
        }
        return true;
    }

    return (
        <form 
			onSubmit={onSubmit.bind(this)}
		>
            <TextField 
				id='name' 
				label='Name' 
				variant='filled' 
				color='primary' 
                placeholder='John Smith' 
				required 
				fullWidth 
				sx={{marginTop: 3}} 
				onChange={(e) => handleOnChange(e)} 
				value={nameValue}
			/>
            <TextField 
				id='email' 
				label='E-Mail' 
				variant='filled' 
				color='primary' 
                placeholder='example@domain.com' 
				required 
				fullWidth 
				sx={{marginTop: 2}} 
				onChange={(e) => handleOnChange(e)} 
				value={emailValue}
                helperText={errorValue ? errors.email : ''} 
				error={errorValue}
			/>
            <TextField 
				id='phone-number' 
				label='Phone Number' 
				variant='filled' 
				color='primary' 
                placeholder='(999) 999-9999' 
				required 
				fullWidth 
				sx={{marginTop: 2}} 
				onChange={(e) => handleOnChange(e)} 
				value={phoneValue}
			/>
            <TextField 
				id='subject' 
				label='Subject' 
				variant='filled' 
				color='primary' 
                placeholder='Subject of message' 
				required 
				fullWidth 
				sx={{marginTop: 2}} 
				onChange={(e) => handleOnChange(e)} 
				value={subjectValue}
			/>
            <TextField 
				id='message' 
				label='Message' 
				multiline 
				variant='filled' 
				rows={4} 
                placeholder='Message' 
				color='primary' 
				required 
				fullWidth 
				sx={{marginTop: 2}} 
				onChange={(e) => handleOnChange(e)} 
				value={messageValue}
			/>
            <Button 
				id='submitButton' 
				type='submit' 
				fullWidth 
				sx={{marginTop: 1, backgroundColor: '#3D6B9F'}} 
				endIcon={<SendIcon/>}
			>
                {buttonValue}
            </Button>
        </form>
    );
}

export default ContactForm;