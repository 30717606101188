
/*
 * define text animations for navigation bar 
 */

import { blue } from "@mui/material/colors"
import { fadeInto } from "../animations/animations"

export const companyLogoBlueAnimation = {
    animation: `${fadeInto} ease 0.6s;`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const companyLogoWhiteAnimation = {
    marginLeft: 1,
    marginRight: 2,
    opacity: 0,
    animationDelay: '0.6s !important',
    animation: `${fadeInto} ease 0.6s;`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const slideshowFadeInto = {
    marginTop: 12,
    opacity: 0,
    animationDelay: '1.2s !important',
    animation: `${fadeInto} ease 1s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const slideshowTransition = {
    opacity: 0,
    animation: `${fadeInto} ease 2s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const slideshowScaleTransition = {
    opacity: 0,
    animation: `${fadeInto} ease 2s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    width: '300px',
    height: '400px'
}

export const VideoPlayer = {
    opacity: 0,
    marginTop: 12,
    backgroundColor: blue,
    animation: `${fadeInto} ease 2s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const AdditionalContent = {
    marginTop: 1,
    opacity: 0,
    animationDelay: '1s !important',
    animation: `${fadeInto} ease 3s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const AlbumContent = {
    marginTop: 1,
    opacity: 0,
    animationDelay: '2s !important',
    animation: `${fadeInto} ease 3s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const PosterContent = {
    marginTop: 1,
    opacity: 0,
    animationDelay: '2s !important',
    animation: `${fadeInto} ease 3s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const AboutMeContent = {
    marginLeft: -3,
    opacity: 0,
    animation: `${fadeInto} ease 3s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const InstaFeedContent = {
    marginTop: 6,
    opacity: 0,
    animation: `${fadeInto} ease 3s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards'
}

export const CenterDivContent = {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
}

export const VideoCardsStyle = {
    backgroundColor: 'hsla(227, 15%, 15%, 1)',
    width: '350px',
    height: '275px',
}