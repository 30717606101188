
/* export function for importing multimedia */
export function MultiMedia(media, name) {
    /* media is the context of this function */
    media = media.toUpperCase();
    let assets = null;
    let raw = null;
    let albumData = [];
    let data = [];
    const albumSet = new Set();

    switch(media) {
        case "POSTERS-2016":
            assets = require.context('/public/assets/pictures/posters/2016', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2016/');
            }
            break;
        case "POSTERS-2017":
            assets = require.context('/public/assets/pictures/posters/2017', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2017/');
            }
            break;
        case "POSTERS-2018":
            assets = require.context('/public/assets/pictures/posters/2018', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2018/');
            }
            break;
        case "POSTERS-2019":
            assets = require.context('/public/assets/pictures/posters/2019', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2019/');
            }
            break;
        case "POSTERS-2020":
            assets = require.context('/public/assets/pictures/posters/2020', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2020/');
            }
            break;
        case "POSTERS-2021":
            assets = require.context('/public/assets/pictures/posters/2021', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2021/');
            }
            break;
        case "POSTERS-2022":
            assets = require.context('/public/assets/pictures/posters/2022', true, /\.(png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', '/assets/pictures/posters/2022/');
            }
            break;
        case "ALBUMS":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                albumSet.add(raw[i].split("/")[1]);
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH.substring(7))
            }
            albumSet.forEach(element => {
                for (let j = 0; j < data.length; j++) {
                    if (data[j].includes(element)) {
                        albumData.push([element, data[j]]);
                        break;
                    }
                }
            });
            break;
        case "PHOTOS_EVENTS":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_EVENTS, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_EVENTS.substring(7));
            }
            break;
        case "PHOTOS_GRADUATION-ADELLE":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_GRADUATION_ADELLE, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_GRADUATION_ADELLE.substring(7));
            }
            break;
        case "PHOTOS_GRADUATION-DANIELA":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_GRADUATION_DANIELA, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_GRADUATION_DANIELA.substring(7));
            }
            break;
        case "PHOTOS_GRADUATION-LEO":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_GRADUATION_LEO, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_GRADUATION_LEO.substring(7));
            }
            break;
        case "PHOTOS_GRADUATION-MATTHEW":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_GRADUATION_MATTHEW, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_GRADUATION_MATTHEW.substring(7));
            }
            break;
        case "PHOTOS_REAL-ESTATE":
            assets = require.context(process.env.REACT_APP_ALBUM_PATH_REAL_ESTATE, true, /\.(PNG|png|jpe?g|svg)$/);
            raw = assets.keys();
            for (let i = 0; i < raw.length; i++) {
                data[i] = raw[i].replace('./', process.env.REACT_APP_ALBUM_PATH_REAL_ESTATE.substring(7));
            }
            break;
        default:
            break;
    }
    return [data, albumData];
}
