
import { useState, useEffect } from 'react';

// material ui
import { Container, useMediaQuery, Stack, MobileStepper, Button, Typography, Select, FormControl, InputLabel, MenuItem, Box } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// components
import NavigationBar from '../NavigationBar';
import { Slideshow } from '../Slideshow';
import { PosterImages } from '../PosterImages';
import { VideoCard } from '../VideoCard';
import { MultiMedia } from '../../utils/MultiMedia';
// styles
import { VideoPlayer, slideshowFadeInto, AdditionalContent } from '../../styles/styles';

const Cinematography = () => {

	// methods prior to intialization
	const determineMaxAllowedCards = () => {
        let width = window.innerWidth;
        if (width >= 1200) {
            return 3;
        }
        else if (width >= 600) {
            return 2;
        }
        else {
            return 1;
        }
    };

	// hooks
	const [videoIdOnPlayer, setVideoIdOnPlayer] = useState(0);
	const [maxAllowedCards, setMaxAllowedCards] = useState(determineMaxAllowedCards());
	const [activeContentGroup, setActiveContentGroup] = useState(0);
	const [firstVideoPlayedAlready, setFirstVideoPlayedAlready] = useState(false);
	const [posterYear, setPosterYear] = useState('2022');

	// local variables
	// styling
	let videoPlayerDivStyling = {
		position: 'relative', 
		width: '100%', 
		height: 0, 
		paddingBottom: '56.25%'
	};

	let videoPlayeriFrameStyling = {
		position: 'absolute', 
		top: 0, 
		left: 0, 
		width: '100%', 
		height: '100%'
	};
	// video cards displayed
	let cardsDisplayed = 0;

	// content
	let videos = [
		{title: 'Writhe (2016)', url: 'https://www.youtube.com/embed/MYfLFifaaCs?rel=0&amp;autoplay=1'},
		{title: 'A Journey\'s Past (2017)', url: 'https://www.youtube.com/embed/JoH7HKbytkk?rel=0&amp;autoplay=1'},
		{title: 'Kinker\'s Lament (2017)', url: 'https://www.youtube.com/embed/bjT_8f-867s?rel=0&amp;autoplay=1'},
		{title: 'This December -- Brooke and Brie Music Video 2017', url: 'https://www.youtube.com/embed/_7tHZMpbr6s?rel=0&amp;autoplay=1'},
		{title: 'New Horizons Documentary (2017)', url: 'https://www.youtube.com/embed/2HmCRlbCSGk?rel=0&amp;autoplay=1'},
		{title: 'One Final Moment (2017)', url: 'https://www.youtube.com/embed/CmnbyBkQb10?rel=0&amp;autoplay=1'},
		{title: 'Commission Number 3 (2016)', url: 'https://www.youtube.com/embed/ayBznfPElA4?rel=0&amp;autoplay=1'},
		{title: 'My Successor (2019)', url: 'https://www.youtube.com/embed/5r3P1u4F5C8?rel=0&amp;autoplay=1'},
		{title: 'Identity (2016)', url: 'https://www.youtube.com/embed/oO6wWLcCpDs?rel=0&amp;autoplay=1'},
		{title: 'Lame Superpowers Anonymous (2016)', url: 'https://www.youtube.com/embed/ZszYfH1qdDU?rel=0&amp;autoplay=1'},
		{title: 'Psychosomatic (2017)', url: 'https://www.youtube.com/embed/D8H_dY6cqXA?rel=0&amp;autoplay=1'},
		{title: '99% Alcohol (2017)', url: 'https://www.youtube.com/embed/-cLL7eFxQz8?rel=0&amp;autoplay=1'}
	];

	let posters = {
		'2016': Object.values(MultiMedia('posters-2016'))[0],
		'2017': Object.values(MultiMedia('posters-2017'))[0],
		'2018': Object.values(MultiMedia('posters-2018'))[0],
		'2019': Object.values(MultiMedia('posters-2019'))[0],
		'2020': Object.values(MultiMedia('posters-2020'))[0],
		'2021': Object.values(MultiMedia('posters-2021'))[0],
		'2022': Object.values(MultiMedia('posters-2022'))[0]
	}

	let author = 'Alejandro Hernandez';

	let youtubeAllowedPlugins = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;'

	// methods
	const shouldDisplayCard = (videoId) => {
		cardsDisplayed++;
		if (cardsDisplayed <= maxAllowedCards) {
			if (videoId < videos.length) {
				return true;
			}
		}
		return false;
	}

	/**
	 * 
	 * @param {Integer} offset - offset to calibrate next video in sequence
	 * @returns {Integer} Integer pertaining to next video Id
	 */
	const getVideoIndexGivenOffset = (offset) => {
		return (activeContentGroup + 1) * maxAllowedCards - (maxAllowedCards - offset);
	}

	// hook methods
	const handleVideoOnPlayerChange = (videoId) => {
		setVideoIdOnPlayer(videoId);
		if (!firstVideoPlayedAlready) {
			setFirstVideoPlayedAlready(!firstVideoPlayedAlready);
		}
	}

	const handlePosterYearChange = (event) => {
		setPosterYear(event.target.value);
	}

	/* stepper component for switching videos */
	/**
	 * @returns {Integer} Integer pertaining to number of steps in stepper component
	 */
	const determineNumberOfSteps = () => {
		return Math.ceil(videos.length / maxAllowedCards);
	}

	// navigation handlers
	const handleNextGroupChange = () => {
		setActiveContentGroup(activeContentGroup + 1);
	}

	const handlePreviousGroupChange = () => {
		setActiveContentGroup(activeContentGroup - 1);
	}

	/**
	 * Determine if navigation button should be disabled
	 * @param {String} navigationType Next or Previous.
	 * @returns {Boolean}
	 */
	const shouldDisableNavigation = (navigationType) => {
		switch(navigationType.toLowerCase()) {
			case 'next':
				return activeContentGroup === determineNumberOfSteps() - 1;
			case 'previous':
				return activeContentGroup === 0;
			default:
				return true;
		}
	}

	// listener handlers
	const handleScreenResizeListener = () => {
		setMaxAllowedCards(determineMaxAllowedCards());
		if (activeContentGroup >= determineNumberOfSteps()) {
			setActiveContentGroup(determineNumberOfSteps() - 1)
			return;
		}
	}

	// effects
	useEffect(() => {
		window.addEventListener('resize', handleScreenResizeListener);
	});

	return (
		<>
			<NavigationBar 
				link={1}
			/>
			<Container
				maxWidth='lg'
				sx={VideoPlayer}
			>
				<div 
					style={videoPlayerDivStyling}
				>
					<iframe 
						width='100%' 
						style={videoPlayeriFrameStyling}
						src={(videoIdOnPlayer === 0 && !firstVideoPlayedAlready) 
								? videos[videoIdOnPlayer].url + '&mute=1'
								: videos[videoIdOnPlayer].url
							}
						frameBorder='0' 
						allow={youtubeAllowedPlugins}
						title='iframe'
					/>
				</div>
			</Container>
			<Container
				maxWidth='lg'
				sx={AdditionalContent}
			>
				<Stack spacing={1} direction='row' justifyContent='center' alignItems='center'>
					{
						shouldDisplayCard(getVideoIndexGivenOffset(0)) 
						&& 
						<VideoCard 
							title={videos[getVideoIndexGivenOffset(0)].title}
							subtitle={author} 
							videoUrl={videos[getVideoIndexGivenOffset(0)].url} 
							callback={handleVideoOnPlayerChange} 
							videoId={getVideoIndexGivenOffset(0)} 
							videoOnPlayer={videoIdOnPlayer}
						/>
					}
					{
						shouldDisplayCard(getVideoIndexGivenOffset(1)) 
						&& 
						<VideoCard 
							title={videos[getVideoIndexGivenOffset(1)].title}
							subtitle={author} 
							videoUrl={videos[getVideoIndexGivenOffset(1)].url} 
							callback={handleVideoOnPlayerChange} 
							videoId={getVideoIndexGivenOffset(1)} 
							videoOnPlayer={videoIdOnPlayer}
						/>
					}
					{
						shouldDisplayCard(getVideoIndexGivenOffset(2)) 
						&& 
						<VideoCard 
							title={videos[getVideoIndexGivenOffset(2)].title}
							subtitle={author} 
							videoUrl={videos[getVideoIndexGivenOffset(2)].url} 
							callback={handleVideoOnPlayerChange} 
							videoId={getVideoIndexGivenOffset(2)} 
							videoOnPlayer={videoIdOnPlayer}
						/>
					}
				</Stack>
				<MobileStepper 
					variant='dots' 
					steps={
						Math.ceil(videos.length / maxAllowedCards)
					} 
					position='static' 
					activeStep={activeContentGroup}
					nextButton={
						<Button 
                            size='small' 
                            onClick={handleNextGroupChange} 
                            disabled={shouldDisableNavigation('next')} 
				            endIcon={
										<KeyboardArrowRight 
											style={
												(shouldDisableNavigation('next'))
													? {fill: '#5A595A'} 
													: {fill: 'white'}
											}
										/>
									} 
							disableFocusRipple 
							disableRipple
						>
							Next
						</Button>
					}
					backButton={
						<Button 
							size='small' 
							onClick={handlePreviousGroupChange} 
							disabled={shouldDisableNavigation('previous')} 
							startIcon={
										<KeyboardArrowLeft 
											style={
												(shouldDisableNavigation('previous')) 
													? {fill: '#5A595A'}
													: {fill: 'white'}
											}
										/>
									} 
							disableFocusRipple 
							disableRipple
						>
							Previous
						</Button>
					}
				/>
			</Container>
			<Container
				maxWidth='lg'
				sx={slideshowFadeInto}
			>
				<Typography 
						variant='h4' 
						color='primary' 
						align='center'
					>
						Filmography
					</Typography>
				<Box
					display='flex'
					margin='auto'
					alignItems='center'
					justifyContent='center'
				>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
						<InputLabel>Poster Year</InputLabel>
						<Select
						value={posterYear}
						onChange={handlePosterYearChange}
						label="Year"
						>
							<MenuItem value='2022'>2022</MenuItem>
							<MenuItem value='2021'>2021</MenuItem>
							<MenuItem value='2020'>2020</MenuItem>
							<MenuItem value='2019'>2019</MenuItem>
							<MenuItem value='2018'>2018</MenuItem>
							<MenuItem value='2017'>2017</MenuItem>
							<MenuItem value='2016'>2016</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Container>
			{
				useMediaQuery('(max-width:1000px)') ?
				<Container 
					maxWidth='lg' 
					sx={slideshowFadeInto}
				>
					<Typography 
						variant='h4' 
						color='primary' 
						align='center'
					>
						{posterYear}
					</Typography>
					<Slideshow 
						imagesArray={posters[posterYear]}
						scale
						titleBar
					/>
            	</Container>
				: 
				<Container
					maxWidth='lg'
					sx={slideshowFadeInto}
				>
					<PosterImages
						year={posterYear}
					/>
				</Container>
			}
			<Typography 
				variant='h6' 
				color='primary' 
				align='center' 
				style={{padding: 30}}
			>
				&copy; 2016 - {new Date().getFullYear()} Alejandro Hernandez
			</Typography>
		</>
	);

}

export default Cinematography;